import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UIkit from 'uikit'
import style from 'uikit/dist/css/uikit.css'
import Icons from 'uikit/dist/js/uikit-icons';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './custom.css'
import About from './About';
import Sponsors from './Sponsors';
UIkit.use(Icons);


const Isabi = () => {
  return(
  <Router>
      <div>
          <Switch>
              <Route path="/" exact component={App} />
              <Route path="/about" exact component={About} />
              <Route path="/sponsors" exact component={Sponsors} />
              {/* <Route component={Error404} /> */}
          </Switch>
      </div>
  </Router>)
}

ReactDOM.render(
  <React.StrictMode>
    <Isabi />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
