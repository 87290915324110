import React from 'react';
import logo from '../img/logo_w.svg'

function Nav() {
    return (
        <div>
            <div className="uk-position-top uk-visible@m uk-margin-large-top">
                <nav className="uk-navbar-container uk-container uk-navbar-transparent " data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav isabi-nav">
                            <li className="uk-active"><a href="/"><img src={logo} width='300' alt='logo' /></a></li>
                            <li className='uk-margin-large-left'><a href="/">&nbsp;</a></li>
                            <li><a href="/"></a></li>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About the Contest</a></li>
                            {/* <li><a href="/sponsors">Sponsors</a></li> */}
                            {/* <li><a href="/">Contact</a></li> */}
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="uk-position-top uk-hidden@m uk-margin-large-top">
                <nav className="uk-navbar-container uk-container uk-navbar-transparent " data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav isabi-nav">
                            <li className="uk-active"><a href="/"><img src={logo} width='200' alt='logo' /></a></li>
                            {/* <li  className='uk-margin-large-left'><a href="/">&nbsp;</a></li>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About the Contest</a></li>
                            <li><a href="/">Sponsors</a></li>
                            <li><a href="/">Contact</a></li> */}
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        <a className="uk-navbar-toggle toggle" data-uk-navbar-toggle-icon href="#"> </a>
                    </div>
                </nav>
            </div>
        </div>

    );
}

export default Nav;
