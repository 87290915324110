import React from 'react';
import Nav from './common/Nav'
import bg from './img/about.png'
import group from './img/about_group.png'
import logo from './img/logo.svg';


function About() {
    return (
        <div>
            <section className="uk-position-relative uk-visible@m">
                <div className="uk-cover-container uk-height-medium" >
                    <img src={bg} alt="" data-uk-cover />
                    <Nav />
                    <div className='uk-position-bottom'>
                        <div className='uk-container uk-margin-large-bottom'>
                            <div data-uk-grid>
                                <div className='uk-width-3-5@m'>
                                    <div className='isabi-header-solid'></div>
                                    <h5 className='isabi-mini-header uk-margin-small-top'> ABOUT US </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-position-relative uk-hidden@m">
                <div className="uk-cover-container uk-height-medium" >
                    <img src={bg} alt="" data-uk-cover />
                    <Nav />
                    <div className='uk-position-bottom'>
                        <div className='uk-container uk-margin-large-bottom'>
                            <div data-uk-grid>
                                <div className='uk-width-3-5@m'>
                                    <div className='isabi-header-solid_m'></div>
                                    <h5 className='isabi-mini-header_m uk-margin-small-top'> ABOUT US </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='barley-section uk-visible@m'>
                <section className='uk-section'>
                    <div className='uk-container'>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid'></div>
                                <h1 className='isabi-section-header'>About <br />The Contest</h1>
                                <p className='isabi-section-text uk-margin-top'>
                                    The Inter-States Agricultural Business Innovation (I-SABI) Project Contest,is a Micro, Small and Medium Enterprises (MSMEs) development intervention plan designed by Walklink Services Limited as a platform for multi-stakeholder synergy for the promotion and projection of the Agro policies and achievement of the present government for the stimulation of agro business revolution, wealth/job creation and massive unemployment reduction.
</p>
                            </div>
                            <div className="uk-width-3-5@m ">
                                <img src={group} alt="" />
                            </div>
                            <div className="uk-width-1-1@m ">
                                <p className='isabi-section-text uk-margin-top'>
                                    I-SABI contest is a proposed reality Television (TV) show aimed at

                                    bridging the gap arising from the tide of unemployment, youth restiveness, ethnoreligious disintegration and lack of acceptable reality TV show in Nigeria. This is to create a multi- stakeholder educative platform with the potentials of MSMEs development for massive job/wealth creation for the reduction of unemployment. It’s a private sector driven initiative for massive entrepreneurship and MSMEs development along the Agricultural Value-Chain (PRODUCTION, PROCESSING, PACKAGING, MARKETING). The project overall objectives are as follows
                                </p>
                                <ul className='isabi-section-text'>
                                    <li>To stimulate the establishment of about five thousand (5000) Micro, Small and Medium Enterprises in Nigeria by 2024;</li>
                                    <li>To create an alternatively acceptable reality TV show program to educate and endowed Nigerians on Modern Agricultural business innovation practices along the agro value-chain; and</li>
                                    <li>To create a platform for national integration and reorientation.</li>
                                </ul>
                                <div className='isabi-section-solid uk-margin-large-top'></div>
                                <h1 className='isabi-section-header'>Project <br />Components & Contents </h1>

                                <p className='isabi-section-text uk-margin-top'>
                                    The project content is centered on Agricultural Value-Chains with components spread along strategic trainings on latest innovations on Agriculture/Agribusiness, Entrepreneurship master class trainings for business development, leadership masterclass trainings for personal/interpersonal development, alternative dispute resolution masterclass trainings, talk shows on ethics & values for national re-orientation/integration, live debates on trending national issues, networking, business mentoring sessions on Agro-business ideas and innovations. The project contest will be based on inter-state teams arrangement. A total number of one hundred and eleven (111) participants from the thirty six (36) states and the Federal Capital Territory (FCT), Abuja, will be admitted into the “House,” after a successful auditioning process at the states and FCT. In each state and FCT, three (3) participants will be selected through an auditioning process from a pool of shortlisted applicants.
                                </p>
                                <p className='isabi-section-text uk-margin-top'>

                                    The contest in the “House” which shall be between these one hundred and eleven (111) participants/housemates listed above shall last for a period of ninety (90) days, which is three (3) calendar months. Each state and the FCT will be represented by three (3) participants or contestants or housemates as shown above. There will be nominations by the housemates in teams followed by viewers voting for possible evictions of teams after the first month (30 days) duration. Series of evictions will follow within the second and third months (60 to 90 days) to have ten (10) teams or states emerging as finalists. Each team or state in the final stage (10) will leave the “House” with a price of Ten Million (N10, 000, 000) naira, as starter pack or initial capital for the Agricultural business venture. Other prices will also be won including the star price, first and second runner ups. That’s the three best teams or states. The sum of two hundred thousand (N200, 000) naira will be awarded to all teams or states for participating. However, the project shall continue to make available Agricultural business innovation tips including answers

                                    to questions to every applicant on our website, accessible with the use of their application codes. During the project live show, it’s also expected that millions of viewers within and outside Nigeria by watching and participating will be endowed with skills and knowledge to establish Agricultural businesses with technical support from the project.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section className='barley-section uk-hidden@m'>
                <section className='uk-section'>
                    <div className='uk-container'>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid_m'></div>
                                <h1 className='isabi-section-header_m'>About <br />The Contest</h1>
                                <p className='isabi-section-text_m uk-margin-top'>I sabi contest is an MSMEs development intervention plan designed by Walklink Services Limited as a platform for multi-stakeholder synergy for the promotion and/projection of the agro policies and achievement of the present government for the stimulation of agro business revolution, wealth/job creation and massive unemployment reduction.  <br /><br />
                                I sabi contest, a reality TV show is an acronym for Inter- State Agriculture & Business Innovation.</p>
                            </div>
                            <div className="uk-width-3-5@m ">
                                <img src={group} alt="" />
                            </div>
                            <div className="uk-width-1-1@m ">
                                <p className='isabi-section-text_m uk-margin-top'>
                                    A private sector driven initiative for massive entrepreneurship and MSMEs development along the agricultural Value-Chain (PRODUCTION, PROCESSING, PACKAGING, MARKETING).
                                    The live reality television show will house some of the selected participants from the Thirty-Six States of the Federation plus FCT in master class development trainings, mentorship, entertainment and warm business competition.
                                    Our aim is to use the platform to increase access by MSMEs to financial services, increase employment opportunities, increase productivity/output of Microenterprises, create wealth and jobs, engender inclusive growth and NGDP.
                                    We will be working with the Small, Micro Enterprises Development Agency of Nigeria (SMEDAN), Ministry of Agriculture, Agricultural Research Council of Nigeria, The Nigeria Incentive-Based Risk Sharing System for Agricultural Lending (NIRSAL), Bank of Agriculture, World Bank, Bank of Industry, Central Bank of Nigeria, relevant MDAs and development partners to implement the multi-stakeholder reality TV show which promises to be impactful.
                                </p>
                                <div className='isabi-section-solid uk-margin-large-top'></div>
                                <h1 className='isabi-section-header_m'>Project <br />Components & Contents </h1>

                                <p className='isabi-section-text_m uk-margin-top'>
                                    The project content is centered on Agricultural Value-Chain with components spread along strategic trainings on latest innovations on Agriculture/agribusiness, Entrepreneurship master class trainings for business development, leadership master class trainings for personal/interpersonal development, alternative dispute resolution master class trainings, talk shows on ethics & values for national re-orientation/integration, live debates on trending national issues, Networking, Business mentoring sessions, featuring inter-state team contest on agro business ideas and innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className='uk-section footer-section'>
                <div className='uk-container'>
                    <div className="uk-text-center" data-uk-grid>
                        <div className="uk-width-1-4@m">
                            <img src={logo} alt='logo' />
                        </div>
                        <div className="uk-width-3-4@m">
                            <p className='uk-width-3-4@m footer-text uk-text-small uk-text-left'>
                                I sabi contest is an MSMEs development intervention plan designed as a platform for multi-stakeholder synergy
                                for the promotion and/projection of the agro policies and achievement of the present government for the
                                stimulation of agro business revolution, wealth/job creation and massive unemployment reduction.
              </p>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    );
}
export default About;