import React from 'react';
import Nav from './common/Nav'
import logo from './img/logo.svg'
import play from './img/play.svg';
import bg from './img/bg.png';
import tractorBg from './img/tractor_bg.png';
import group from './img/group.png';
import groupOne from './img/group1.png';
import gall_1 from './img/gallery/1.jpg';
import gall_2 from './img/gallery/2.jpg';
import gall_3 from './img/gallery/3.jpg';
import gall_4 from './img/gallery/4.jpg';
import gall_5 from './img/gallery/5.jpg';
import gall_6 from './img/gallery/6.jpg';
import gall_7 from './img/gallery/7.jpg';
import gall_8 from './img/gallery/8.jpg';
import gall_9 from './img/gallery/9.jpg';
import gall_10 from './img/gallery/10.jpg';
import gall_11 from './img/gallery/11.jpg';
import gall_12 from './img/gallery/12.jpg';
import tony from './img/team/tony.jpeg';
import ubi from './img/team/ubi.jpg';
import abdul from './img/team/abdul.jpg';
import peter from './img/team/peter.jpg';
import shine from './img/team/shine.jpg';
import kingsley from './img/team/kingsley.jpg';
import daily_trust from './img/daily_trust.png'
import visit1 from './img/gallery/visit1.jpg'
import visit2 from './img/gallery/visit2.jpg'
import visit3 from './img/gallery/visit3.jpg'
import visit4 from './img/gallery/visit4.jpg'
import almat1 from './img/gallery/almat1.jpg'
import almat2 from './img/gallery/almat2.jpg'
import almat3 from './img/gallery/almat3.jpg'
import venue1 from './img/gallery/venue1.png'
import venue2 from './img/gallery/venue2.png'
import venue3 from './img/gallery/venue3.png'
import venue4 from './img/gallery/venue4.png'
import venue5 from './img/gallery/venue5.png'
import venue6 from './img/gallery/venue6.png'
import venue7 from './img/gallery/venue7.png'
import venue8 from './img/gallery/venue8.png'
import venue9 from './img/gallery/venue9.png'
import venue10 from './img/gallery/venue10.png'
import venue11 from './img/gallery/venue11.png'
import venue14 from './img/gallery/venue14.png'



import arcn from './img/sponsor/arcn.png';
import fg from './img/sponsor/fg.png';
import smedan from './img/sponsor/smedan.png';
import thrama from './img/sponsor/thrama.png';
import walklink from './img/sponsor/walklink.png';
import naic from './img/sponsor/naic.png';
import raw from './img/sponsor/raw.png';
import boa from './img/sponsor/boa.png';
import lev from './img/sponsor/lev.png';
import almat from './img/sponsor/almat.png';
import ntsp from './img/sponsor/ntsp.png';
import nirsal from './img/sponsor/nirsal.png';
import wa from './img/sponsor/wa.png';
import keystone from './img/sponsor/keystone.png';





function App() {
  return (
    <div>
      <section className="uk-position-relative uk-visible@m">
        <div className="uk-cover-container " data-uk-height-viewport>
          <img src={bg} alt="" data-uk-cover />
          <Nav />
          <div className='uk-position-bottom'>
            <div className='uk-container uk-margin-large-bottom'>
              <div data-uk-grid>
                <div className='uk-width-4-5@m'>
                  <div className='isabi-header-solid'></div>
                  <h5 className='isabi-mini-header uk-margin-small-top'>WELCOME TO ISABI </h5>
                  <h1 className='isabi-header uk-margin-large-bottom'>
                    Inter-States Agricultural <br /> Business Innovation Contest
                  </h1>
                  <a data-uk-toggle="target: #isabi-youtube" >
                    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                      <div className="uk-width-auto">
                        <img width="40" height="40" src={play} />
                      </div>
                      <div className="uk-width-expand">
                        <p className='isabi-section-text-white_m '> Watch I-SABI National Project Coordinator (NPC)<br /> Ubi E. Ikpi , discuss the I-SABI Contest </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="uk-position-relative uk-hidden@m">
        <div className="uk-cover-container " data-uk-height-viewport>
          <img src={bg} alt="" data-uk-cover />
          <Nav />
          <div className='uk-position-bottom'>
            <div className='uk-container uk-margin-large-bottom'>
              <div data-uk-grid>
                <div className='uk-width-3-5@m'>
                  <div className='isabi-header-solid_m'></div>
                  <h5 className='isabi-mini-header_m uk-margin-small-top'>WELCOME TO ISABI </h5>
                  <h1 className='isabi-header_m uk-margin-large-bottom'>
                    Inter-States Agricultural <br />Business <br /> Innovation Contest
                  </h1>
                  <a data-uk-toggle="target: #isabi-youtube" >
                    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                      <div className="uk-width-auto">
                        <img width="40" height="40" src={play} />
                      </div>
                      <div className="uk-width-expand">
                        <p className='isabi-section-text-white_m '> Watch I-SABI National Project Coordinator (NPC) Ubi E. Ikpi , discuss the I-SABI Contest </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="isabi-youtube" className="uk-flex-top" data-uk-modal>
        <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" data-uk-close></button>
          <iframe src="https://www.youtube.com/embed/IKZR0GhFbsQ" width="1280" height="720" frameborder="0" data-uk-video data-uk-responsive></iframe>
        </div>
      </div>
      <section className='barley-section '>
        <section className='uk-section'>
          <div className='uk-container '>
            <div className="uk-width-2-5@m">
              <div className='isabi-section-solid'></div>
              <h1 className='isabi-section-header uk-margin-large-bottom'>Partners</h1>
            </div>
            <div class="uk-child-width-1-2 uk-child-width-1-5@m uk-text-center uk-margin-large-bottom" data-uk-grid>

              <img src={naic} alt="" />
              <img src={raw} alt="" />
              <img src={fg} alt="" />
              <img src={smedan} alt="" />
              <img src={boa} alt="" />
              <img src={lev} alt="" />
              <img src={almat} alt="" />
              <img src={arcn} alt="" />
              <img src={thrama} alt="" />
              <img src={walklink} alt="" />
              <img src={ntsp} alt="" />
              {/* <img src={nirsal} alt="" /> */}
              <img src={wa} alt="" />
              <img src={keystone} alt="" />
            </div>

          </div>
        </section>
      </section>
      <section className='barley-section uk-visible@m'>
        <section className='uk-section'>
          <div className='uk-container'>
            <div className="" data-uk-grid>
              <div className="uk-width-2-5@m">
                <div className='isabi-section-solid'></div>
                <h1 className='isabi-section-header'>Unlocking the<br /> Agro Value-chain<br />Potentials For<br />Massive Job Creation</h1>
                <p className='isabi-section-text uk-margin-top'>This Contest is centered on Agricultural Value-Chains with components spread along strategic trainings on latest innovations on Agriculture/Agribusiness, Entrepreneurship master class trainings for business development, leadership masterclass trainings for personal/interpersonal development, alternative dispute resolution masterclass trainings, talk shows on ethics & values for national re-orientation/integration, live debates on trending national issues, networking, business mentoring sessions on Agro-business ideas and innovations</p>
              </div>
              <div className="uk-width-3-5@m ">
                <img src={group} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className='uk-section'>
          <div className='uk-container'>
            <div className='isabi-section-solid uk-align-center'></div>
            <h1 className='isabi-section-header uk-text-center'>How the ISABI Reality<br /> TV Show works</h1>
            <div className="uk-flex uk-flex-center">
              <div className=" uk-text-center uk-width-2-3@m uk-margin-large-top" data-uk-grid>
                <h4 className='isabi-section-text uk-margin-top uk-text-center'>Inter-States Agricultural Business Innovation (I-SABI) Project Contest will be based on inter-state teams arrangement. A total number participants from the thirty six (36) states and the Federal Capital Territory (FCT), Abuja, will be admitted into the “House,” after a successful auditioning process at the states and FCT. In each state and FCT, participants will be selected through an auditioning process from a pool of shortlisted applicants</h4>
                <h4 className='isabi-section-text uk-margin-top uk-text-center uk-margin-large-top'>These participants/housemates listed above shall last for a period of Sixty (60) days, which is three (2) calendar months and the Contest will be broken into the following Rounds</h4>


                <div className='uk-width-1-1'>
                  <div className="uk-child-width-1-2 uk-text-left" data-uk-grid>
                    <div>
                      <div className='num-1'>
                        <h1 className='isabi-number-header uk-text-center uk-padding'>Preliminaries</h1>
                      </div>
                    </div>
                    <div>
                      <div className='num-2'>
                        <h1 className='isabi-number-header uk-text-center uk-padding'>Second Round</h1>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <div className='uk-width-1-1'>
                  <div className="uk-child-width-1-2 uk-text-left" data-uk-grid>
                    <div>
                      <div className='num-3'>
                        <h1 className='isabi-number-header uk-text-center uk-padding '>Third Round</h1>
                      </div>
                    </div>
                    <div>
                      <div className='num-4'>
                        <h1 className='isabi-number-header uk-text-center uk-padding'>Grand Finale</h1>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <h4 className='isabi-section-text uk-margin-top uk-text-center'>There will be nominations by the housemates in teams followed by viewers voting for possible evictions of teams after the first month (30 days) duration. Series of evictions will follow within the second month  to have ten (10) teams or states emerging as finalists.</h4>
              </div>
            </div>
            <div className='uk-margin-large-top'>
              <h4 className='isabi-section-text uk-margin-top uk-text-center uk-margin-remove-bottom '>The 10 teams or states in the final stage will leave the “House” with a prize of</h4>
              <h1 className='uk-margin-top uk-text-center isabi-header-prize uk-margin-remove'>₦ 10,000,000</h1>
              <h2 className='uk-text-center isabi-section-text uk-margin-remove'>(10 Million Naira) each</h2>
              <h4 className='isabi-section-text uk-margin-top uk-text-center uk-margin-remove-bottom '>as starter pack or initial capital for the Agricultural business venture.</h4>
            </div>
            {/* <div className='isabi-section-solid uk-align-center'></div> */}

            {/* <div className='uk-margin-top'>
              <h4 className='isabi-section-text uk-margin-top uk-text-center uk-margin-remove-bottom '>The 10 teams or states in the final stage will leave the “House” with a prize of</h4>
              <h1 className='uk-margin-top uk-text-center isabi-header-prize uk-margin-remove'>₦ 10,000,000</h1>
              <h2 className='uk-text-center isabi-section-text uk-margin-remove'>(10 Million Naira)</h2>
            </div> */}

          </div>
        </section>
        {/* <section className='uk-section'>
          <div className='uk-container'>
            <div className='isabi-section-solid uk-align-center'></div>
            <h1 className='isabi-section-header uk-text-center'>The Venue</h1>
            <h3 className='isabi-section-text uk-margin-top uk-text-center uk-margin-remove-bottom '>ALMAT Farms <br /> <span className='uk-text-small'>ALMAT Drive, Kuje, Abuja, Nigeria</span>
            </h3>
            <div className='uk-margin-top' data-uk-grid>
                <div className='uk-width-1-4@s'>
                  <img src={venue1} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue2} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue3} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue4} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue5} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue6} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue7} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue8} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue9} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue10} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue11} alt="" />
                </div>
                <div className='uk-width-1-4@s'>
                  <img src={venue14} alt="" />
                </div>
               
              </div>

          </div>
        </section> */}
      </section>


      <section className='barley-section uk-hidden@m'>
        <section className='uk-section'>
          <div className='uk-container'>
            <div className="" data-uk-grid>
              <div className="uk-width-2-5@m">
                <div className='isabi-section-solid_m'></div>
                <h1 className='isabi-section-header_m'>Unlocking the<br /> Agro Value-chain<br />Potentials For<br />Massive Job Creation</h1>
                <p className='isabi-section-text_m uk-margin-top'>This Contest is centered on Agricultural Value-Chains with components spread along strategic trainings on latest innovations on Agriculture/Agribusiness, Entrepreneurship master class trainings for business development, leadership masterclass trainings for personal/interpersonal development, alternative dispute resolution masterclass trainings, talk shows on ethics & values for national re-orientation/integration, live debates on trending national issues, networking, business mentoring sessions on Agro-business ideas and innovations</p>
              </div>
              <div className="uk-width-3-5@m ">
                <img src={group} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className='uk-section'>
          <div className='uk-container'>
            <div className='isabi-section-solid_m uk-align-center'></div>
            <h1 className='isabi-section-header_m uk-text-center'>How the ISABI Reality<br /> TV Show works</h1>
            <div className="uk-flex uk-flex-center">
              <div className=" uk-text-center uk-width-2-3@m uk-margin-large-top" data-uk-grid>
                <h4 className='isabi-section-text_m uk-margin-top uk-text-center'>Inter-States Agricultural Business Innovation (I-SABI) Project Contest will be based on inter-state teams arrangement. A total number participants from the thirty six (36) states and the Federal Capital Territory (FCT), Abuja, will be admitted into the “House,” after a successful auditioning process at the states and FCT. In each state and FCT, participants will be selected through an auditioning process from a pool of shortlisted applicants</h4>
                <h4 className='isabi-section-text_m uk-margin-top uk-text-center uk-margin-large-top'>These participants/housemates listed above shall last for a period of Sixty (60) days, which is three (2) calendar months and the Contest will be broken into the following Rounds</h4>

                <div className='uk-width-1-1'>
                  <div className="uk-child-width-1-2@m uk-text-left" data-uk-grid>
                    <div>
                      <div className='num-1'>
                        <h1 className='isabi-number-header_m uk-text-center uk-padding'>Preliminaries</h1>
                      </div>
                    </div>
                    <div>
                      <div className='num-2'>
                        <h1 className='isabi-number-header_m uk-text-center uk-padding'>Second Round</h1>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <div className='uk-width-1-1'>
                  <div className="uk-child-width-1-2@m uk-text-left" data-uk-grid>
                    <div>
                      <div className='num-3'>
                        <h1 className='isabi-number-header_m uk-text-center uk-padding '>Third Round</h1>
                      </div>
                    </div>
                    <div>
                      <div className='num-4'>
                        <h1 className='isabi-number-header_m uk-text-center uk-padding'>Grand Finale</h1>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <h4 className='isabi-section-text_m uk-margin-top uk-text-center'>There will be nominations by the housemates in teams followed by viewers voting for possible evictions of teams after the first month (30 days) duration. Series of evictions will follow within the second month (60 days) to have ten (10) teams or states emerging as finalists.</h4>
                <div className='uk-margin-large-top'>
                  <h4 className='isabi-section-text_m uk-margin-top uk-text-center uk-margin-remove-bottom '>The 10 teams or states in the final stage will leave the “House” with a prize of</h4>
                  <h1 className='uk-margin-top uk-text-center isabi-header-prize_m uk-margin-remove'>₦ 10,000,000</h1>
                  <h2 className='uk-text-center isabi-section-text_m uk-margin-remove'>(10 Million Naira) each</h2>
                  <h4 className='isabi-section-text_m uk-margin-top uk-text-center uk-margin-remove-bottom '>as starter pack or initial capital for the Agricultural business venture.</h4>
                </div>
                {/* <div className='isabi-section-solid uk-align-center'></div> */}
              </div>
            </div>
          </div>
        </section>
        <section className='uk-section'>
          <div className='uk-container'>
            <div className='isabi-section-solid uk-align-center'></div>
            <h1 className='isabi-section-header_m uk-text-center'>The Venue</h1>
            <h3 className='isabi-section-text_m uk-margin-top uk-text-center uk-margin-remove-bottom '>ALMAT Farms <br /> <span className='uk-text-small'>ALMAT Drive, Kuje, Abuja, Nigeria</span>
            </h3>
            <div className='uk-margin-top' data-uk-grid>
              <div className='uk-width-1-4@s'>
                <img src={venue1} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue2} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue3} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue4} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue5} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue6} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue7} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue8} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue9} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue10} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue11} alt="" />
              </div>
              <div className='uk-width-1-4@s'>
                <img src={venue14} alt="" />
              </div>

            </div>

          </div>
        </section>
      </section>

      <section className='uk-visible@m'>
        <div className="uk-cover-container">
          <canvas width="" height="700px"></canvas>
          <img src={tractorBg} alt="" data-uk-cover />
          <div className='uk-position-top'>
            <div className='uk-container uk-margin-large-top'>
              <div data-uk-grid>
                <div className='uk-width-2-5@m'>
                  <div className='isabi-section-solid uk-margin-large-top'></div>
                  <h1 className='isabi-section-header-white uk-margin-large-bottom'>
                    A Private Sector Driven <br />initiative for massive entrepreneurship and MSMEs development
                  </h1>
                  <p className='isabi-section-text-white uk-margin-top'>
                    This is a multi-stakeholder educative platform targeted at MSMEs development for massive job/wealth creation for the reduction of unemployment.
                    <br /><br />It’s a private sector driven initiative for massive entrepreneurship and MSMEs development along the Agricultural Value-Chain (PRODUCTION, PROCESSING, PACKAGING, MARKETING)
                  </p>
                </div>
                <div className="uk-width-3-5@m ">
                  <img src={groupOne} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='uk-hidden@m'>
        <div className="uk-cover-container">
          <canvas width="" height="800px"></canvas>
          <img src={tractorBg} alt="" data-uk-cover />
          <div className='uk-position-top'>
            <div className='uk-container uk-margin-large-top'>
              <div data-uk-grid>
                <div className='uk-width-2-5@m'>
                  <div className='isabi-section-solid_m uk-margin-large-top'></div>
                  <h1 className='isabi-section-header-white_m uk-margin-large-bottom'>
                    A Private Sector Driven <br />initiative for massive entrepreneurship and MSMEs development
                  </h1>
                  <p className='isabi-section-text-white_m uk-margin-top'>
                    This is a multi-stakeholder educative platform targeted at MSMEs development for massive job/wealth creation for the reduction of unemployment.
                    <br /><br />It’s a private sector driven initiative for massive entrepreneurship and MSMEs development along the Agricultural Value-Chain (PRODUCTION, PROCESSING, PACKAGING, MARKETING)
                  </p>
                </div>
                <div className="uk-width-3-5@m ">
                  <img src={groupOne} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='uk-section'>
        <div className='uk-container'>
          <div className='isabi-section-solid uk-align-center'></div>
          <h1 className='isabi-section-header uk-text-center'>The ISABI Contest Management Team</h1>
          <div className="uk-text-center uk-margin-xlarge-top uk-grid-small" data-uk-grid>
            {/* <div className="uk-width-1-5@m">
              <div className="uk-card uk-card-default uk-card-small">
                <div className="uk-card-media-top">
                  <img src={tony} alt="" />
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title isabi-address-header">Anthony Amalu</h3>
                  <p className='isabi-section-text'>MD/CEO</p>
                  <a className=" isabi-section-text_m" data-uk-toggle="target: #tony">View Bio</a>
                  <div id="tony" className="uk-flex-top" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

                      <button className="uk-modal-close-default" type="button" data-uk-close></button>
                      <img src={tony} alt='' width='150' />
                      <h4 className='isabi-address-header'></h4>
                      <p className='isabi-section-text'>MD/CEO</p>
                      <p className='isabi-section-text_m'>
                        Mr. Tony Amalu, an indigen of Eke in Udi LGA , Enugu State, is a renowned and quintessential Public Administrator PGD, Enugu State University Science and Technology (ESUT), Mass Communication with HND in Mass Communication from the prestigious Institute of Management and Technology (IMT), Enugu
<br /><br />
He is the founder of Muslim & Christian Youths Integration for Good Leadership (MCY), founder of Nigerian Union of Sales Rep. (NUSREP).
<br /><br />
Mr. Tony is currently the MD/CEO of Walklink Services Ltd. and Big Brain Concept. He is well trained in Human Resources and Financial Management.
<br /><br />
As a former student Leader, he was speaker of the parliament of the Student Union Government.
<br /><br />
Mr. Tony Amalu is a God fearing man, A President Zion Family Mission International, a philanthropist per excellence
<br /><br />
As a family man, Mr. Tony is blessed with a beautiful wife and three wonderful kids.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="uk-width-1-5@m">
              <div className="uk-card uk-card-default uk-card-small">
                <div className="uk-card-media-top">
                  <img src={ubi} alt="" />
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title isabi-address-header">Ubi E. Ikpi</h3>
                  <p className='isabi-section-text'>National Project Coordinator (NPC)</p>
                  <a className=" isabi-section-text_m" data-uk-toggle="target: #ubi">View Bio</a>
                  <div id="ubi" className="uk-flex-top" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

                      <button className="uk-modal-close-default" type="button" data-uk-close></button>
                      <img src={ubi} alt='' width='150' />
                      <h4 className='isabi-address-header'>Ubi E. Ikpi</h4>
                      <p className='isabi-section-text'>National Project Coordinator (NPC)</p>
                      <p className='isabi-section-text_m'>
                        Place of work: Agricultural Research Council of Nigeria (ARCN), Abuja. <br />
                        Position: Assistant Chief Agricultural Officer (ACAO)<br />
                        Qualifications & Trainings: <br />
                        1. Australia Awards training on Increasing the Developmental Impact of Agricultural Research, University of Sydney, Sydney, Australia, 2018;<br />
                        2.  Australia Awards training on Dry land Farming / Agriculture,  University of Queensland, Brisbane,  Queensland, Australia,  2012;<br />
                        3.  Bachelor of Science Degree in Genetics & Biotechnology, University of Calabar, Calabar,  Cross River State,  2002.<br />
                        Appointments: <br />
                        1. Personal Assistant to the Director, Plant Resources Department, ARCN; <br />
                        2. Personal Assistant to the Director,  Partnerships & Linkages Department;<br />
                        3. Head of secretariat, ARCN/Dipton collaboration project;<br />
                        4. Technical & Administrative Assistant to Biosafety & Biotechnology Rapid Assessment Policy Platform (BioRAPP) project; <br />
                        5. Member / Head of secretariat, workshop on documentation of Agricultural Technologies generated by the National Agricultural Research Institutes (NARIs); <br />
                        6. Administrative Secretary,  Academic Staff Union of Research Institutes (ASURI) Reform Group; <br />
                        7. Member, ARCN Committee on Technical Assistance and Donor support projects; <br />
                        8. Member, Committee on tree planting and landscaping of ARCN premise;<br />
                        9. Member,  Intra-Miniterial Committee on the Review and validation of the draft Agricultural policy of Nigeria; <br />
                        10. Member of secretariat for the Committee on ARCN reforms; <br />
                        11. Member / Secretary of the Committee on the draft of strategy document for the revival of the Nationally Coordinated Research Projects (NCRPs); <br />
                        12. Member of Local Organising Committees (LOC), Agricultural Society of Nigeria (ASN), 2013, 2017 and 2018 annual conferences;<br />
                        13. Secretary, Academic Staff Union of Research Institutions (ASURI), ARCN branch, 2012.to March, 2020; <br />
                        14. Secretary, Joint Action Committee, ARCN,  2012.to 2019;<br />
                        15. Member, Committee on the formulation of Monitoring & Evaluation template for ARCN; <br />

                        Awards: <br />
                        1. President's National  Youths Service Corps (NYSC) Honours Award,  2004/2005<br />
                        2. Oyo state best Corps member Award,  2004/2005;<br />
                        3. Merit award for excellent performance as the Chairman, NYSC road safety corps,  2004/2005<br />

                        Publication:<br />
                        1. Adopting Biotechnology to empower Cross Riverians, an Article published on Nigerian national dailies, the Chronicle, 24th to 30th March,  2010 (page 18)<br />
                        <br />

                        Happily married to Mary Ubi Ikpi and blessed  with two children (boys); KOKOLOKOBASE and OBASENANTONGHA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-1-5@m">
              <div className="uk-card uk-card-default uk-card-small">
                <div className="uk-card-media-top">
                  <img src={kingsley} alt="" />
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title isabi-address-header">Kingsley Ifeanyichukwu Ogwudu</h3>
                  <p className='isabi-section-text'> </p>
                  <a className=" isabi-section-text_m" data-uk-toggle="target: #abdul">View Bio</a>
                  <div id="abdul" className="uk-flex-top" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

                      <button className="uk-modal-close-default" type="button" data-uk-close></button>
                      <img src={kingsley} alt='' width='150' />
                      <h4 className='isabi-address-header'>Kingsley Ifeanyichukwu Ogwudu</h4>
                      <p className='isabi-section-text'> </p>
                      <p className='isabi-section-text_m'>
                        B.Sc. (Nig), Computer Science/Mathematics (2007), Cert M&E, MNIM.<br /><br />

                        Principal Partner Gaps&Bridges Resources, Country Coordinator Muslim & Christian Youths integration for good Leadership Initiative. (MCY International)<br /><br />

                        Former Assistant National Coordinator/ Founding Partner WISE-Project Nigeria Managing Partner REEL Management Consult Services Limited<br /><br />

                        A vibrant articulated and self-motivated Development Professional and Trainer. Well trained in Project Management, Monitoring and Evaluation by E&F Management Consult in Collaboration with Global Public Health Solutions Atlanta G.A and U S Centre for Disease Control & Prevention (CDC), through African Field Epidemiology Training Network (AFENET) Uganda, in the first Nigeria Professional Monitoring & Evaluation Course.<br /><br />

                        He has vast experience of over Seven years in Project / Content design, implementation, Management, Monitoring and Evaluation.<br /><br />

                        Kingsley has worked as Project Supervising Consultant, MINOM Global Limited, Project Coordinator UNAA-Abuja/North Dakota State University (USA) Joint Training Programs, former project Officer Child Right Initiatives and Independent Consultants Enugu. Former Strategic Knowledge Management/ Administrative Assistant National Agency for the Control of Aids, (NACA) Abuja.<br /><br />

                        His other skills and expertise includes but not limited to professional data collection, analysis and interpretation, Training Facilitation and Coordination. He is certified in Alternative Dispute Resolution (ADR). He has worked in the areas of public health, NGOs, Educational, Small Business Management and Entrepreneurship Development to mention a few. He is a research associate with Favtoma Group. He is also the National Coordinator Muslim and Christian Youth integration for Good Leadership Initiative, He was the Assistant National Coordinator of the famous who is self employable project (Wise-p Nig), a youth empowerment and mentorship project.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-1-5@m">
              <div className="uk-card uk-card-default uk-card-small">
                <div className="uk-card-media-top">
                  <img src={abdul} alt="" />
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title isabi-address-header">Abdullateef  Adorere  Aliu</h3>
                  <p className='isabi-section-text'> </p>
                  <a className=" isabi-section-text_m" data-uk-toggle="target: #abdul">View Bio</a>
                  <div id="abdul" className="uk-flex-top" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

                      <button className="uk-modal-close-default" type="button" data-uk-close></button>
                      <img src={abdul} alt='' width='150' />
                      <h4 className='isabi-address-header'>Abdullateef  Adorere  Aliu</h4>
                      <p className='isabi-section-text'> </p>
                      <p className='isabi-section-text_m'>
                        Abdullateef Adorere Aliu  is an Assistant  Chief. Administrative Officer (ACAO) with the Agricultural Research Council of Nigeria (ARCN), Abuja, Nigeria. He is a native of Ebira, Okene local Government Area in Kogi State.
                        <br /><br />
                        He obtained his first degree in Business Administration (B.Sc) from the Ahmadu Bello University, Zaria in 2001 and  Masters in Business Administration (MBA) from Bayero University, Kano in 2006 respectively.
                        <br /><br />
                        He is a hardworking and motivated Business Analyst who has acquired extensive knowledge in the field through the study of Business &Management at degree level and previous work experience in establishing requirements, system improvements to increase productivity and costs.
                        <br /><br />
                        Additionally, he has a huge strength and skills and experience in managing and co-ordinating a group work force in a work environment with over 3,000 hours of experience involving a multitude of analytical tasks.
                        <br /><br />
                        He gas contributed immensely to improving organizational business by successfully managing projects resulting in increase performance and productivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="uk-width-1-5@m">
              <div className="uk-card uk-card-default uk-card-small">
                <div className="uk-card-media-top">
                  <img src={peter} alt="" />
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title isabi-address-header">Peter Ndirpaya</h3>
                  <p className='isabi-section-text'>ICT & Technology</p>
                  <a className=" isabi-section-text_m" data-uk-toggle="target: #">View Bio</a>
                  <div id="peter" className="uk-flex-top" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

                      <button className="uk-modal-close-default" type="button" data-uk-close></button>
                      <img src={peter} alt='' width='150' />
                      <h4 className='isabi-address-header'>Peter Ndirpaya</h4>
                      <p className='isabi-section-text'> </p>
                      <p className='isabi-section-text_m'>

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

        </div>
      </section>



      <section className='uk-section'>
        <div className='uk-container'>
          <div data-uk-grid>
            <div className='uk-width-1-3@s'>
              <img src={gall_1} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_2} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_3} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_4} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_5} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_6} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_7} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_8} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_9} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_10} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_11} alt="" />
            </div>
            <div className='uk-width-1-3@s'>
              <img src={gall_12} alt="" />
            </div>


          </div>
        </div>
      </section>

      <section className='uk-section'>
        <div className='uk-container'>
          <div className='isabi-section-solid uk-align-center'></div>
          <h1 className='isabi-section-header uk-text-center'>In the news</h1>
          <p className='isabi-section-text uk-margin-top uk-text-center'>Some news and publications about The ISABI Contest.</p>
          <div className="uk-text-center uk-padding-large" data-uk-grid>
            <div className="uk-width-1-3@m uk-text-left">
              <a href='https://www.dailytrust.com.ng/economic-diversification-forum-organises-contest-to-boost-agric-culture.html' target='blank_'>
                <div className="uk-card uk-card-default uk-card-body">
                  <h3 className="uk-card-title"><img src={daily_trust} alt='daily_trust' width='50%' /></h3>
                  <p className='isabi-section-text uk-margin-top'>Economic diversification: Forum organises contest to boost agric culture</p>
                </div>
              </a>
            </div>
            <div className="uk-width-1-1@m uk-text-left">
              <p className='isabi-blog-text uk-margin-top'>Courtesy Visit <span style={{ color: 'rgba(112,112,112,0.5)' }}>· June 9, 2020</span></p>
              <div className='uk-margin-top' data-uk-grid>
                <div className='uk-width-1-3@s'>
                  <img src={visit1} alt="" />
                </div>
                <div className='uk-width-1-3@s'>
                  <img src={visit2} alt="" />
                </div>
                <div className='uk-width-1-3@s'>
                  <img src={visit3} alt="" />
                </div>
              </div>

              <div className="uk-margin-top">
                <h5 className='isabi-section-text uk-margin-top'>National Coordinator of I-SABI contest  project in a strategic meeting with the Directors of Raw Materials Research and Development Council Mr John Obekpa and Mr M.S Musa.</h5>

              </div>
            </div>
            <div className="uk-width-1-1@m uk-text-left">
              <p className='isabi-blog-text uk-margin-top'>Team Visit <span style={{ color: 'rgba(112,112,112,0.5)' }}>· July 11, 2020</span></p>
              <div className='uk-margin-top' data-uk-grid>
                <div className='uk-width-1-3@s'>
                  <img src={almat1} alt="" />
                </div>
                <div className='uk-width-1-3@s'>
                  <img src={almat2} alt="" />
                </div>
                <div className='uk-width-1-3@s'>
                  <img src={almat3} alt="" />
                </div>
              </div>

              <div className="uk-margin-top">
                <h5 className='isabi-section-text uk-margin-top'>Visit of the ISABI Contest Team to Almat Farms, Kuje Abuja</h5>
              </div>
            </div>
            <div className="uk-width-1-1@m uk-text-left">
              <p className='isabi-blog-text uk-margin-top'>Team Visit <span style={{ color: 'rgba(112,112,112,0.5)' }}>· 2020</span></p>
              <div className='uk-margin-top' data-uk-grid>
                <div className='uk-width-1-3@s'>
                  <img src={visit4} alt="" />
                </div>
              </div>

              <div className="uk-margin-top">
                <h5 className='isabi-section-text uk-margin-top'>Meeting with Leventis Foundation Nigeria and I-SABI contest team.</h5>
              </div>
            </div>
            {/* <div className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body">1-3</div>
            </div>
            <div className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body">Expand</div>
            </div> */}
          </div>

        </div>
      </section>

      <section className='uk-section uk-visible@m'>
        <div className='uk-container'>
          <div data-uk-grid>
            <div className='uk-width-1-3@m'>
              <div className='isabi-section-solid'></div>
              <h1 className='isabi-section-header'>Contact Us</h1>

              <h1 className='isabi-address-header'>Address</h1>
              <p className='isabi-section-text uk-margin-top'>
                Suit 4, Mangal Plaza, <br />Plot 1 Goroua Street, <br />
                Off  Nouakcott Street, <br />Wuse Zone 1, Abuja
              </p>

              <h1 className='isabi-address-header'>Phone Number</h1>
              <p className='isabi-section-text uk-margin-top'>
                +234 8119814483 <br />+234 9080707684
              </p>

              <h1 className='isabi-address-header'>Email Address</h1>
              <p className='isabi-section-text uk-margin-top'>
                <a className='isabi-email-link' href='mailto:info@isabicontest.com'>info@isabicontest.com</a><br />
                <a className='isabi-email-link' href='mailto:isabicontest@gmail.com'>isabicontest@gmail.com</a>
              </p>
            </div>
            <div className='uk-width-2-3@m'>
              <form className="uk-grid-small uk-margin-large-top" data-uk-grid>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label" htmlFor="fname">First Name</label>
                  <input className="uk-input isabi-form" id="fname" type="text" placeholder="Enter Firstname" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label" htmlFor="sname"> Surname</label>
                  <input className="uk-input isabi-form" id='sname' type="text" placeholder="Enter Surname" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label" htmlFor="email"> Email</label>
                  <input className="uk-input isabi-form" id='email' type="text" placeholder="Enter Email Address" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label" htmlFor="email"> Phone Number</label>
                  <input className="uk-input isabi-form" id='phone' type="text" placeholder="Enter Phone Number" required />
                </div>
                <div className="uk-width-1-1">
                  <label className="uk-form-label isabi-label" htmlFor="add"> Address</label>
                  <input className="uk-input isabi-form" id='add' type="text" placeholder="Enter Address" required />
                </div>
                <div className="uk-width-1-1">
                  <label className="uk-form-label isabi-label" htmlFor="add"> Message</label>
                  <textarea className="uk-textarea isabi-form" rows="5" placeholder="Message" required></textarea>
                </div>
                <div className="uk-width-1-1">
                  <button className="uk-button isabi-button-lg uk-button-default uk-button-large" type='submit'>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='uk-section uk-hidden@m'>
        <div className='uk-container'>
          <div data-uk-grid>
            <div className='uk-width-1-3@m'>
              <div className='isabi-section-solid_m'></div>
              <h1 className='isabi-section-header_m'>Contact Us</h1>

              <h1 className='isabi-address-header_m'>Address</h1>
              <p className='isabi-section-text_m uk-margin-top'>
                Suit 4, Mangal Plaza, <br />Plot 1 Goroua Street, <br />
                Off  Nouakcott Street, <br />Wuse Zone 1, Abuja
              </p>

              <h1 className='isabi-address-header_m'>Phone Number</h1>
              <p className='isabi-section-text_m uk-margin-top'>
                +234 8119814483 <br />+234 9080707684
              </p>

              <h1 className='isabi-address-header_m'>Email Address</h1>
              <p className='isabi-section-text_m uk-margin-top'>
                <a className='isabi-email-link' href='mailto:isabicontest@gmail.com'>isabicontest@gmail.com</a>
              </p>
            </div>
            <div className='uk-width-2-3@m'>
              <form className="uk-grid-small uk-margin-large-top" data-uk-grid>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label_m" htmlFor="fname">First Name</label>
                  <input className="uk-input isabi-form" id="fname" type="text" placeholder="Enter Firstname" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label_m" htmlFor="sname"> Surname</label>
                  <input className="uk-input isabi-form" id='sname' type="text" placeholder="Enter Surname" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label_m" htmlFor="email"> Email</label>
                  <input className="uk-input isabi-form" id='email' type="text" placeholder="Enter Email Address" required />
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label isabi-label_m" htmlFor="email"> Phone Number</label>
                  <input className="uk-input isabi-form" id='phone' type="text" placeholder="Enter Phone Number" required />
                </div>
                <div className="uk-width-1-1">
                  <label className="uk-form-label isabi-label_m" htmlFor="add"> Address</label>
                  <input className="uk-input isabi-form" id='add' type="text" placeholder="Enter Address" required />
                </div>
                <div className="uk-width-1-1">
                  <label className="uk-form-label isabi-label_m" htmlFor="add"> Message</label>
                  <textarea className="uk-textarea isabi-form" rows="5" placeholder="Message" required></textarea>
                </div>
                <div className="uk-width-1-1">
                  <button className="uk-button isabi-button-lg uk-button-default uk-button-large" type='submit'>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='uk-section footer-section'>
        <div className='uk-container'>
          <div className="uk-text-center" data-uk-grid>
            <div className="uk-width-1-4@m">
              <img src={logo} alt='logo' />
            </div>
            <div className="uk-width-3-4@m">
              <p className='uk-width-3-4@m footer-text uk-text-small uk-text-left'>
                Inter-States Agricultural Business Innovation (I-SABI) Project Contest is a Micro, Small and Medium Enterprises (MSMEs) development intervention plan developed for the stimulation of agro business revolution, wealth/job creation and massive unemployment reduction in Nigeria.
              </p>
            </div>

          </div>
        </div>

      </section>
    </div >
  );
}

export default App;
