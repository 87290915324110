import React from 'react';
import Nav from './common/Nav'
import bg from './img/about.png'
import group from './img/about_group.png'
import logo from './img/logo.svg';
import arcn from './img/sponsor/arcn.png';
import fg from './img/sponsor/fg.png';
import smedan from './img/sponsor/smedan.png';
import thrama from './img/sponsor/thrama.png';
import walklink from './img/sponsor/walklink.png';


function About() {
    return (
        <div>
            <section className="uk-position-relative uk-visible@m">
                <div className="uk-cover-container uk-height-medium" >
                    <img src={bg} alt="" data-uk-cover />
                    <Nav />
                    <div className='uk-position-bottom'>
                        <div className='uk-container uk-margin-large-bottom'>
                            <div data-uk-grid>
                                <div className='uk-width-3-5@m'>
                                    <div className='isabi-header-solid'></div>
                                    <h5 className='isabi-mini-header uk-margin-small-top'> SPONSORS AND PARTNERS </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-position-relative uk-hidden@m">
                <div className="uk-cover-container uk-height-medium" >
                    <img src={bg} alt="" data-uk-cover />
                    <Nav />
                    <div className='uk-position-bottom'>
                        <div className='uk-container uk-margin-large-bottom'>
                            <div data-uk-grid>
                                <div className='uk-width-3-5@m'>
                                    <div className='isabi-header-solid_m'></div>
                                    <h5 className='isabi-mini-header_m uk-margin-small-top'>SPONSORS AND PARTNERS </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='barley-section uk-visible@m'>
                <section className='uk-section'>
                    <div className='uk-container'>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid'></div>
                                <h1 className='isabi-section-header'>Partners</h1>
                                <img src={arcn} alt="" />
                            </div>
                        </div>
                        <div className="" data-uk-grid>
                            <div className="uk-width-12@m">
                                <div className='isabi-section-solid'></div>
                                <h1 className='isabi-section-header'>Endorsement</h1>
                                <img src={fg} alt="" />
                                <img src={smedan} alt="" />
                            </div>
                        </div>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid'></div>
                                <h1 className='isabi-section-header'>Sponsorship</h1>
                                <img src={thrama} alt="" />
                                <img src={walklink} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section className='barley-section uk-hidden@m'>
                <section className='uk-section'>
                    <div className='uk-container'>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid_m'></div>
                                <h1 className='isabi-section-header_m'>Partners</h1>
                                <img src={arcn} alt="" />
                            </div>
                        </div>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid_m'></div>
                                <h1 className='isabi-section-header_m'>Endorsement</h1>
                                <img src={fg} alt="" />
                                <img src={smedan} alt="" />
                            </div>
                        </div>
                        <div className="" data-uk-grid>
                            <div className="uk-width-2-5@m">
                                <div className='isabi-section-solid_m'></div>
                                <h1 className='isabi-section-header_m'>Sponsorship</h1>
                                <img src={thrama} alt="" />
                                <img src={walklink} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className='uk-section footer-section'>
                <div className='uk-container'>
                    <div className="uk-text-center" data-uk-grid>
                        <div className="uk-width-1-4@m">
                            <img src={logo} alt='logo' />
                        </div>
                        <div className="uk-width-3-4@m">
                            <p className='uk-width-3-4@m footer-text uk-text-small uk-text-left'>
                                I sabi contest is an MSMEs development intervention plan designed as a platform for multi-stakeholder synergy
                                for the promotion and/projection of the agro policies and achievement of the present government for the
                                stimulation of agro business revolution, wealth/job creation and massive unemployment reduction.
              </p>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    );
}
export default About;